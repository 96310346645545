<template>
  <div>
    <el-form :model="form"
             label-width="80px">
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="科目">
            <el-select style="width:100%"
                       v-model="form.subject_id"
                       clearable
                       placeholder="选择科目">
              <el-option v-for="item in suubjectList"
                         :key="item.subject_id"
                         :label="item.subject_name"
                         :value="item.subject_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="试卷状态">
            <el-select style="width:100%"
                       v-model="form.status"
                       clearable
                       placeholder="请选择">
              <el-option v-for="item in statusList"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-button type="primary"
                     @click="search">查询</el-button>
          <el-button type="primary"
                     @click="exportExcel">导出</el-button>
        </el-col>
      </el-row>
    </el-form>
    <div style="padding:10px"
         class="use_info"
         v-if="form.user_id">
      <span>学生姓名：{{userInfo.nickname|| userInfo.truename}} </span>
      <span>学生手机号：{{userInfo.verifiedMobile}}</span>
      <span>用户ID：{{userInfo.id}}</span>
      <span>学校:{{userInfo.school_name}}</span>
      <span> 班级:{{userInfo.class_name}}</span>

    </div>
    <el-table border
              stripe
              highlight-current-row
              :max-height="$store.state.tableHeight"
              :data="tableData"
              style="width: 100%">
      <el-table-column prop="user_paper_id"
                       width="100"
                       align="center"
                       label="试卷ID" />
      <el-table-column prop="subject_name"
                       align="center"
                       label="科目" />
      <el-table-column prop="module_name"
                       align="center"
                       label="试卷类型" />
      <div v-if="userInfo.id == ''">
        <el-table-column prop="user_id"
                         align="center"
                         label="用户ID" />
        <el-table-column prop="mobile"
                         align="center"
                         label="用户ID" />
        <el-table-column prop="nickname"
                         align="center"
                         label="用户姓名" />
      </div>
      <el-table-column prop="paper_time"
                       align="center"
                       label="试卷时间" />
      <el-table-column prop="name"
                       align="center"
                       label="试卷批改状态">
        <template slot-scope="{row}">
          <span>{{statusList.find(item=>item.value == row.judge_status).label}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="paper_score"
                       align="center"
                       label="试卷总分" />
      <el-table-column align="center"
                       label="学生得分">
        <template slot-scope="{row}">
          <span v-if="row.user_score == -1">
            未批改
          </span>
          <span v-else>
            {{row.user_score}}
          </span>
        </template>
      </el-table-column>
      <el-table-column align="center"
                       width="120"
                       label="操作">
        <template slot-scope="{row}">
          <el-button type="text"
                     @click="toInfo(row)">试卷详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [],
      page: 1,
      size: 10,
      totalElements: 0,
      form: {},
      userInfo: {},
      tableData: [],
      suubjectList: [],
      statusList: [
        { label: '未提交', value: 0 },
        { label: '未批改', value: 1 },
        { label: '批改中', value: 3 },
        { label: '批改完成', value: 4 },
      ]
    }
  },
  created () {
    let form = JSON.parse(this.$route.query.queryInfo)
    form.subject_id = ''
    form.status = ''
    this.form = form
  },
  mounted () {
    this.getSubject()
  },
  methods: {
    exportExcel () {

      let form = JSON.parse(JSON.stringify(this.form))
      this.$http({
        url: 'api/v1/student/export_user_paper_detail',
        responseType: "blob",
        method: 'get',
        params: form
      }).then(res => {


        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res)

        a.download = `${this.userInfo.nickname|| this.userInfo.truename}试卷情况统计.xlsx`
        a.click()
      })
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    initData (page, limit) {
      let form = JSON.parse(this.$route.query.queryInfo)
      this.form.page = page
      this.form.limit = limit
      this.form.timeBegin = form.timeBegin
      this.form.timeEnd = form.timeEnd
      this.$http({
        url: '/api/v1/student/get_user_paper_detail',
        method: 'get',
        params: this.form
      }).then(res => {
        this.totalElements = res.data.list.count
        this.tableData = res.data.list.data
        this.userInfo = res.data.user_data
      })
    },
    toInfo (row) {
      this.$router.push('/studentCondition/statistics/info?user_paper_id=' + row.user_paper_id)
    },
    getSubject () {
      this.$http({
        url: '/api/v1/papers/subject',
        method: 'get'
      }).then(res => {
        this.suubjectList = res.data.subject
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.use_info {
  span {
    padding-right: 20px;
  }
}
</style>